<template>
  <div class="height-100">
    <loading v-if="loading"></loading>
    <div v-else>
      <div class="top">
        <div
          style="display: flex; flex-direction: column;margin-left: 0.5rem;padding-top: 0.5rem;padding-bottom: 0.5rem;">
          <span style="font-weight: 600; font-size: 1.1rem;">{{ movieName }}</span>
          <span style=" font-size: 0.8rem; margin-top: 0.5rem;"> {{ movieAddress }}</span>
        </div>
        <!-- <van-icon name="arrow" /> -->
      </div>
      <div style="margin-top: 0.5rem;">
        <carousel-3d :perspective="10" :display="5" :width="110" :height="150" :space="140" :controlsHeight="80"
          :startIndex="startIndex" :on-slide-change="onSlideChanged">
          <slide v-for="(slide, index) in headData" :index="index" :key="index">
            <img style="height: 100%; width: 100%; border-radius: 0.5rem;" :data-index="index" :src="slide.pic">
          </slide>
        </carousel-3d>
        <div class="flex width-100 a-i-c t-a-c jcc" style="padding: 0;">
          <div>
            <span style="font-size: 1.2rem; font-weight: 600;"> {{ headData[headIndex]?.name }}</span>
            <span style="margin-left: 0.5rem; font-size: 1.1rem;color: #ffa976;font-weight: 600;">{{
              headData[headIndex]?.grade }}</span>
            <span style="font-size: 0.85rem;color: #ffa976;">分</span>
          </div>
          <!-- <div
            style="padding: 0.1rem; color: #D33B2E;border: 1px solid #D33B2E; border-radius: 0.2rem; margin-left: 1rem; font-size: 0.5rem;">
            {{ headData[headIndex]?.versionTypes }}
          </div> -->
        </div>
        <div class="flex width-100 a-i-c t-a-c jcc" style="padding-bottom: 1rem;">
          <span style="color: #999; border-radius: 0.2rem; margin-top: 0.5rem; font-size: 0.7rem;">
            {{ headData[headIndex]?.duration }}分钟/ {{ headData[headIndex]?.filmTypes }}
          </span>
          <span @click="goMovieDetail(headData[headIndex])"
            style="font-size: 0.7rem;color: #D23B2E;margin-top: 0.5rem; margin-left: 0.5rem; font-weight: 400; border-bottom: 1px solid #D23B2E;">详情</span>
        </div>
      </div>
      <van-tabs @click="scrollTo" v-model="navIndex" style="margin: 0.57rem 1rem 0.5rem 1rem;">
        <van-tab v-for="(item, index) in data" :title="getTime(item.showTime)" :key="index">
          <div v-for="(item1, index1) in item.futureMovieDetailList" :key="index1" class="flex jcsb  bgc "
            @click="goDetail(item1, item)">
            <div class="flex">
              <div style="min-width: 5rem;">
                <div>
                  <span style="font-weight: 600; font-size: 1.1rem;"> {{ item1.showTime }}</span>
                </div>
                <div style="margin-top: 0.3rem; ">
                  <span class="textTitle"> {{ item1.endTime }}散场</span>
                </div>
              </div>
              <div class="p-lr-10">
                <div>
                  <span style="font-size: 1.05rem;"> {{ item1.showVersionType }}</span>
                </div>
                <div class="textTitle2">
                  <span> {{ item1.hallName }}</span>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: column;min-width: 6rem;text-align: end; align-items: end;">
              <div>
                <span class="price"> {{ $utils.getPricePoint(item1.netPrice) }}</span>
                <span class="yuan"> 点</span>
                <span class="qi"> 起</span>
              </div>
              <div class="buyvote">
                购票
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {

  data() {
    return {
      value: '',
      boo: false,
      address: '北京',
      isLoading: false,
      loading: true,
      navIndex: 0,
      headIndex: 0,
      startIndex: 0,
      movieName: '',
      movieAddress: '',
      form: {
        cardId: '',
        cinemaId: '',
        filmId: '',
        cityCode: '',
        ticketType: '',
        current: 1,
        size: 500
      },

      headData: [],
      data: [],
      time: null,
    };
  },
  components: {
    Carousel3d,
    Slide
  },
  methods: {
    goMovieDetail(val) {
      this.$router.push({
        path: "/MovieDetail",
        query: {
          showbutton: 2,
          item: JSON.stringify(val),
        },
      });
    },
    goDetail(val, val1) {
      this.$router.push({
        path: "/Ticket",
        query: {
          id: val.showId,
          movieName: this.headData[this.headIndex].name,
          cinemaId: this.form.cinemaId,
          fimId: this.form.filmId,
          movieAddress: this.movieAddress,
          cinema_name: this.movieName,
          showDate: val1.showTime,
          startTime: val.showTime,
          endTime: val.endTime,
          hallName: val.hallName,
          cardId: this.form.cardId,
          showVersionType: val.showVersionType,
          moviePicUrl: this.headData[this.headIndex].pic,
        },
      });
    },
    /**
      * 跳转到指定索引的元素
      * @param index
      */
    scrollTo(index, title) {
      this.navIndex = index
      this.$emit('getIndex', index)
    },

    // 刷新
    onRefresh() {
      setTimeout(() => {
        Toast("刷新成功");
        this.getData();
        this.isLoading = false;
      }, 1000);
    },
    getTime(str) {
      return this.$moment(str).format("MM月DD日")
    },
    getTimeQ(str) {
      return this.$moment(str).format("YYYY年MM月DD日")
    },
    //返回
    goback() {
      this.$router.back();
    },

    queryShowsById() {
      this.$api
        .queryShowsByMovieAndFilm(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg)
            return
          }
          this.data = res.data
          if (this.time && this.data && this.data.length > 0) {
            this.data.forEach((element, index) => {
              if (this.time == element.showTime) {
                this.navIndex = index
              }
            });

          }
        })
        .catch();
    },
    onSlideChanged(index) {
      this.headIndex = index
      this.form.filmId = this.headData[index].filmId
      this.data.length = 0
      this.queryShowsById()
    },
    queryAllFilms() {
      const form = {
        cardId: this.form.cardId,
        cinemaId: this.form.cinemaId,
        cityCode: this.form.cityCode,
        ticketType: this.form.ticketType,
      }
      this.$api
        .queryAllFilms(form)
        .then((res) => {

          this.loading = false
          if (res.code == 1) {
            this.$Toast(res.msg)
            return
          }
          this.headData = res.data
          if (this.form.filmId) {
            this.headData.forEach((element, index) => {
              if (element.filmId == this.form.filmId) {
                this.startIndex = index
                this.headIndex = index
              }
            });
          } else { this.form.filmId = this.headData[0].filmId }
          this.queryShowsById()
        })
        .catch((err) => {
          this.$Toast(err);
          this.loading = false
        });
    },
  },
  //详情页获取参数

  mounted() {
    // this.movieDate = JSON.parse(this.$route.query.item)
    this.form.cardId = localStorage.getItem("cardId")
    this.form.cinemaId = this.$route.query.cinemaId
    this.form.filmId = this.$route.query.filmId

    this.movieName = this.$route.query.name
    this.time = this.$route.query.time
    this.movieAddress = this.$route.query.address
    this.form.ticketType = localStorage.getItem("entrCode");
    this.form.cityCode = localStorage.getItem("cityCode")

    this.queryAllFilms()
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 16px;
}

.top {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
}


// ::v-deep .van-tab {
//   position: relative;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-flex: 1;
//   -webkit-flex: 1;
//   flex: 1;
//   -webkit-box-align: center;
//   -webkit-align-items: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   box-sizing: border-box;
//   padding: 0;
//   color: #999;
//   font-size: 0.85rem;
//   line-height: 1.8rem;
//   max-width: 5.7rem;
//   cursor: pointer;
// }



// ::v-deep .van-tabs--line .van-tabs__wrap {
//   height: 2rem;

//   .van-tabs__nav {
//     position: relative;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: flex;
//     background-color: #00000000;
//     -webkit-user-select: none;
//     user-select: none;
//   }
// }

// ::v-deep .van-tab--active {
//   color: white;
//   font-weight: 500;
//   background: #D23B2E;
//   border-radius: 0.9rem;
//   height: 1.8rem;
//   max-width: 5.7rem;
//   margin-top: 0.1rem;
// }

// ::v-deep .van-tabs__line {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   z-index: 1;
//   width: 10.667vw;
//   height: 0;

// }

::v-deep .van-tab {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  color: #999;
  font-size: 0.85rem;
  line-height: 1.8rem;
  cursor: pointer;
  max-width: 5.7rem;
  min-width: 5.7rem;
}



::v-deep .van-tabs--line .van-tabs__wrap {
  height: 2rem;

  .van-tabs__nav {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    background-color: #00000000;
    -webkit-user-select: none;
    user-select: none;
  }
}

::v-deep .van-tab--active {
  color: white;
  font-weight: 500;
  background: #D23B2E;
  border-radius: 0.9rem;
  height: 1.8rem;
  margin-top: 0.1rem;
  max-width: 5.7rem;
}

::v-deep .van-tabs__line {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 10.667vw;
  height: 0;

}

.img {
  height: 6rem;
  width: 5rem;
  margin: 0.5rem;

}

.bgc {
  background-color: #fff;
  border-radius: 0.5rem;
  margin: 0.57rem 0 0 0;
  padding: 1rem;
}




.buyvote {
  color: #EEE;
  margin-top: 0.2rem;
  width: 4rem;
  min-width: 4rem;
  padding: 0.2rem 0;
  text-align: center;
  font-size: 0.7rem;
  border-radius: 1.5rem;
  border: 1px solid #D23B2E;
  color: #D23B2E;
  // background-image: linear-gradient(left, #FF8E57 0%, #D23B2E 100%);

}

.textTitle {
  font-size: 0.85rem;
  color: #999;

}



.textTitle2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #999;
}

.price {
  font-size: 1.1rem;
  color: #D33B2E;
  font-weight: 600;
}

::v-deep .carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  top: 0;
  border-color: #ffffff00;
  border-style: solid;
  background-size: cover;
  background-color: #ffffff00;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
}

.yuan {
  font-size: 0.85rem;
  color: #D33B2E;
}

.qi {
  font-size: 0.8rem;
}
</style>